body {
  font-family: "Lora";
  background-image: url("./113.jpg");
  background-color: #575353;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.about-bg {
  background-image: url("./intro.jpg");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway";
}

p {
  line-height: 1.75;
}

.text-faded {
  color: rgba(255, 255, 255, 0.3);
}

.site-heading {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-transform: uppercase;
  line-height: 1.3;
  font-family: "Raleway";
}
.site-heading .site-heading-upper {
  display: block;
  font-size: 1.4rem;
  font-weight: 100;
}
.site-heading .site-heading-lower {
  font-size: 5rem;
  font-weight: 100;
}

.page-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.section-heading {
  text-transform: uppercase;
}
.section-heading .section-heading-upper {
  display: block;
  font-size: 1rem;
  font-weight: 800;
}
.section-heading .section-heading-lower {
  display: block;
  font-size: 3rem;
  font-weight: 100;
}

.bg-faded {
  background-color: rgba(255, 255, 255, 0.85);
}

.tab {
  margin-left: 70px;
}

#mainNav {
  background-color: #161616;
  max-height: 50px;
  font-family: "Raleway";
}
#mainNav .navbar-brand {
  color: #646464;
}
#mainNav .navbar-nav .nav-item .nav-link {
  color: grey;
  font-weight: 400;
}
#mainNav .navbar-nav .nav-item.active .nav-link {
  color: white;
  font-weight: 600;
}
@media (min-width: 992px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 1.2rem;
  }
  #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav .nav-item.active .nav-link:hover {
    color: #fff;
  }
}

.btn-xl {
  font-weight: 100;
  font-size: 0.9rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.intro {
  position: relative;
}
@media (min-width: 992px) {
  .intro .intro-img {
    width: 75%;
    float: right;
  }
  .intro .intro-text {
    left: 0;
    width: 60%;
    margin-top: 3rem;
    position: absolute;
  }
  .intro .intro-text .intro-button {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2rem;
  }
}
@media (min-width: 1200px) {
  .intro .intro-text {
    width: 45%;
  }
}

.cta {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: rgba(100, 100, 100, 0.9);
}
.cta .cta-inner {
  position: relative;
  padding: 3rem;
  margin: 0.5rem;
  background-color: rgba(255, 255, 255, 0.85);
}
.cta .cta-inner:before {
  border-radius: 0.5rem;
  content: "";
  position: absolute;
  top: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  border: 0.25rem solid rgba(255, 255, 255, 0.85);
}

@media (min-width: 992px) {
  .about-heading .about-heading-img {
    position: relative;
    z-index: 0;
  }
  .about-heading .about-heading-content {
    margin-top: -5rem;
    position: relative;
    z-index: 1;
  }
}

@media (min-width: 992px) {
  .product-item .product-item-title {
    position: relative;
    z-index: 1;
    margin-bottom: -3rem;
  }
  .product-item .product-item-img {
    position: relative;
    z-index: 0;
    max-width: 60vw;
  }
  .product-item .product-item-description {
    position: relative;
    z-index: 1;
    margin-top: -3rem;
    max-width: 50vw;
  }
}

.list-hours {
  font-size: 0.9rem;
}
.list-hours .list-hours-item {
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
  font-style: italic;
}
.list-hours .list-hours-item.today {
  font-weight: bold;
  color: #646464;
}
@media (min-width: 992px) {
  .list-hours {
    width: 50%;
    font-size: 1.1rem;
  }
}

.address strong {
  font-size: 1.2rem;
}

.footer {
  max-height: 100px;
}

.text-primary {
  color: white !important;
}

.bg-primary {
  background-color: #646464 !important;
}

.btn {
  box-shadow: 0px 3px 3px 0px rgba(33, 37, 41, 0.1);
}

.btn-primary {
  background-color: #646464;
  border-color: #646464;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.font-weight-light {
  font-weight: 100 !important;
}
.contact-button:hover {
  text-decoration: underline;
  color: blanchedalmond;
}
